import logo from "../assets/logo.svg";
// import ScrollIntoView from "react-scroll-into-view";

function Header() {
  return (
    <header className="z-10 max-sm:p-[2rem] bg-white flex items-center justify-between px-[5rem] py-[2rem] fixed w-[100%] max-lg:p-[3rem] ">
      <div>
        <img src={logo} alt="Jubal Logo" className="max-sm:w-[10rem]" />
      </div>
      <div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSepP70miAt0Bigg3swKxjAbUk_qdSetfh9uZfOIZRkO90SDQg/viewform?pli=1">
          <button className="bg-jubalViolet text-white px-[2rem] py-[1.5rem]  text-[1.8rem] rounded-[8px] max-sm:py-[1rem] max-sm:px-[1.5rem] max-sm:text-[1.4rem] shadow-[0px 4px 15px 0px #2222221A]">
            Join The Waitlist
          </button>
        </a>
      </div>
    </header>
  );
}
export default Header;
