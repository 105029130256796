import WhyBox from "./WhyBox";
import earn from "../assets/cash-multiple.svg";
import promote from "../assets/bullhorn.svg";
import learn from "../assets/school.svg";
function WhySection() {
  return (
    <section className="py-[10rem] bg-[#f5f6f7] max-lg:px-[5rem] max-sm:py-[3rem] max-sm:px-[2rem]">
      <h1 className="text-[6.4rem] text-center font-bold max-lg:text-[5.2rem] max-sm:text-[3.6rem]">
        Why you should join Jubal now
      </h1>
      <div className="flex items-center justify-center gap-[10rem] mt-[5rem] max-lg:gap-[5rem] max-sm:block  max-lg:flex-wrap max-lg:justify-between">
        <WhyBox
          image={earn}
          header="Earn"
          text="Earn with your music & creative skills and enjoy bespoke services & resources"
        />
        <WhyBox
          image={promote}
          header="Promote"
          text="Showcase your talent and skills to a broader audience."
        />
        <WhyBox
          image={learn}
          header="Upskill"
          text="Get ahead in your creative career with knowledge from the best in the industry"
        />
      </div>
    </section>
  );
}
export default WhySection;
