// eslint-disable-next-line react/prop-types
function TestimonialBox({ image, text, name, role }) {
  return (
    <div className="z-0 min-w-[58rem] text-center border-solid border-white border-[1px] rounded-[5rem] pb-[2rem] bg-[#ffffff20] px-[5rem] backdrop-blur max-sm:min-w-[100%] max-sm:py-[2rem]  ml-[5rem] max-lg:ml-[2rem]">
      <div className="w-[max-content] mx-[auto] relative bottom-12 ">
        <img src={image} alt="image of customer" />
      </div>
      <p className="text-[1.8rem]">{text}</p>
      <p className="text-[2.4rem] mt-[3rem] mb-[1rem] text-jubalYellow font-bold max-sm:mt-[1rem]">
        {name}
      </p>
      <p className="text-[1.8rem]">{role}</p>
    </div>
  );
}
export default TestimonialBox;
