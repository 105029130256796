import { useState } from "react";
import axios from "axios";

function Form() {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    typeOfCreative: "",
  });

  const subscribeUser = async (email) => {
    try {
      const response = await axios.post(
        `/.netlify/functions/waitlist`,
        {
          email,
        }
        // {
        //   headers: {
        //     Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        //   },
        // }
      );
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        // The request was made, but the server responded with an error status code
        console.error(error.response.data);
      } else if (error.request) {
        // The request was made, but there was no response
        console.error("No response from the server");
      } else {
        // Something else happened while setting up the request
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    document.querySelectorAll("input").forEach((input) => (input.value = ""));
    subscribeUser(formData.email);
  }

  return (
    <section
      id="waitlist-form"
      className="bg-jubalBlue mx-[12rem] mt-[5rem] mb-[10rem] rounded-[4rem] text-center text-[#f5f6f7] py-[5rem] max-sm:m-0 max-sm:p-[2rem] max-lg:mx-0 max-lg:rounded-none"
    >
      <h1 className="text-[6rem] font-bold text-center text-[#f5f6f7] max-sm:text-[3.2rem] ">
        Join the Waitlist
      </h1>
      <p className="text-[1.8rem] my-[3rem] max-sm:text-[1.6rem] max-sm:my-[2rem]">
        Sign up now and be the first to enjoy amazing benefits and offers with
        Jubal.
      </p>
      <form
        onSubmit={handleSubmit}
        className="p-[20px] h-[100%] container mx-auto px-[auto] w-[55%] max-sm:w-[100%] hidden"
      >
        <div className="mb-[2rem] text-left">
          <label className=" text-left text-[1.8rem] mb-[2rem]">
            Full Name
            <br />
            <input
              className="mt-[1rem] w-[100%] border-solid border-[1px] border-royalBlue py-[15px] px-[20px] text-[1.8rem] text-black placeholder:text-gray focus:border-[1px] focus:border-gray outline-none rounded-[1rem] max-sm:text-[1.6rem]"
              type="text"
              placeholder="Enter your full name"
              onChange={handleChange}
              name="fullName"
              value={formData.fullName}
            />
          </label>
        </div>
        <div className="mb-[2rem] text-left">
          <label className=" text-left text-[1.8rem] mb-[1rem]">
            Phone Number
            <br />
            <input
              className="mt-[1rem] w-[100%] border-solid border-[1px] border-royalBlue py-[15px] px-[20px] text-[1.8rem] text-black placeholder:text-gray focus:border-[1px] focus:border-gray outline-none rounded-[1rem] max-sm:text-[1.6rem]"
              type="text"
              placeholder="Enter your phone number"
              onChange={handleChange}
              name="phoneNumber"
              value={formData.phoneNumber}
            />
          </label>
        </div>
        <div className="mb-[2rem] text-left">
          <label className=" text-left text-[1.8rem] mb-[1rem]">
            Email Address
            <br />
            <input
              className="mt-[1rem] w-[100%] border-solid border-[1px] border-royalBlue py-[15px] px-[20px] text-[1.8rem] text-black placeholder:text-gray focus:border-[1px] focus:border-gray outline-none rounded-[1rem] max-sm:text-[1.6rem]"
              type="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              name="email"
              value={formData.email}
            />
          </label>
        </div>
        <div className="mb-[2rem] text-left">
          <label className=" text-left text-[1.8rem] mb-[1rem]">
            What type of creative are you?
            <br />
            <input
              className="mt-[1rem] w-[100%] border-solid border-[1px] border-royalBlue py-[15px] px-[20px] text-[1.8rem] text-black placeholder:text-gray focus:border-[1px] focus:border-gray outline-none rounded-[1rem] max-sm:text-[1.6rem]"
              type="text"
              placeholder="E.g Musicain, Producer"
              onChange={handleChange}
              name="typeOfCreative"
              value={formData.typeOfCreative}
            />
          </label>
        </div>
        <div className="mt-[5rem]">
          <button className="bg-jubalViolet text-white px-[2rem] py-[1.5rem]  text-[1.8rem] rounded-[8px] ">
            Join The Waitlist
          </button>
        </div>
      </form>
      <div className="sm:flex mt-[5rem] sm:justify-center">
        <div className=" sm:mr-[2rem] mb-[2rem]">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSepP70miAt0Bigg3swKxjAbUk_qdSetfh9uZfOIZRkO90SDQg/viewform?pli=1">
            <button className="bg-jubalViolet text-white px-[2rem] py-[1.5rem]  text-[1.8rem] rounded-[8px] shadow-[0px 4px 15px 0px #2222221A]">
              Join The Waitlist
            </button>
          </a>
        </div>
        <div>
          <a href="https://chat.whatsapp.com/LVoOl7wgP3ZC9Z5eCr9PJ5">
            <button className="bg-jubalYellow text-white px-[2rem] py-[1.5rem]  text-[1.8rem] rounded-[8px] shadow-[0px 4px 15px 0px #2222221A]">
              Join Jubal Community
            </button>
          </a>
        </div>
      </div>
      {/* <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSepP70miAt0Bigg3swKxjAbUk_qdSetfh9uZfOIZRkO90SDQg/viewform?embedded=true"
        width="700"
        height="520"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="waitlist"
      >
      </iframe> */}
    </section>
  );
}
export default Form;
