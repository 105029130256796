import logo from "../assets/logo-white.svg";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import tiktok from "../assets/tik_tok.svg";
function Footer() {
  return (
    <footer className="bg-gradient-to-b from-[#00798F] via-[#00798f] to-[#262262] text-white px-[12rem] py-[8rem] max-lg:py-[3rem] max-lg:px-[4rem]  max-sm:p-[2rem] max-sm:text-left">
      <div className="flex justify-between items-center max-sm:block">
        <div className="max-sm:mb-[3rem]">
          <img src={logo} alt="jubal logo" className="max-sm:w-[10rem]" />
        </div>
        <div>
          <p className="text-[1.8rem] mb-[2rem] max-sm:text-[1.6rem]">
            Join Us On Socials
          </p>
          <div className="flex">
            <div className="mr-[2rem]">
              <a href="https://www.facebook.com/Jubalworld?mibextid=ZbWKwL">
                <img
                  className="max-lg:w-[4rem]"
                  src={facebook}
                  alt="facebook icon"
                />
              </a>
            </div>
            <div className="mr-[2rem]">
              <a href="https://instagram.com/jubalworld?igshid=NzZlODBkYWE4Ng==">
                <img
                  className="max-lg:w-[4rem]"
                  src={instagram}
                  alt="instagram icon"
                />
              </a>
            </div>
            <div className="mr-[2rem]">
              <a href="https://x.com/Jubalworld_?t=jDOYBGH2Gvp8_pb9k0nXQQ&s=09">
                <img
                  className="max-lg:w-[4rem]"
                  src={twitter}
                  alt="twitter icon"
                />
              </a>
            </div>
            <div>
              <a href="https://www.tiktok.com/@jubalworld?_t=8g7POoNkDpt&_r=1">
                <img
                  className="max-lg:w-[4rem]"
                  src={tiktok}
                  alt="tiktok icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className=" text-center text-[1.8rem] mt-[3rem] max-sm:text-left max-sm:text-[1.6rem]">
        © 2023 Jubal. All rights reserved
      </p>
    </footer>
  );
}
export default Footer;
