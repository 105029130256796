// eslint-disable-next-line react/prop-types
function WhyBox({ image, header, text }) {
  return (
    <div className="min-w-[32rem] max-w-[32rem] h-[40rem] pt-[2rem] pl-[2rem] pr-[3rem] pb-[3rem] bg-white rounded-[3rem] max-sm:min-w-[100%] max-sm:p-[2rem] max-sm:h-[28rem] max-sm:mb-[2rem] max-lg:min-w-[29rem] max-lg:max-w-[29rem]">
      <div className="bg-gradient-to-b from-[#00798F] via-[#00798f] to-[#262262] w-[max-content] p-[1rem] rounded-[5px]">
        <img src={image} alt="" className="max-sm:w-[3rem]" />
      </div>
      <h2 className="text-[4.8rem] font-bold my-[2rem] max-sm:text-[3rem] max-sm:my-[1rem]">
        {header}
      </h2>
      <p className="text-[2.4rem] max-sm:text-[1.8rem]">{text}</p>
    </div>
  );
}
export default WhyBox;
