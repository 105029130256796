import heroImage from "../assets/hero-img.png";
import heroBackgroundOne from "../assets/hero-bg-1.png";
import heroBackgroundTwo from "../assets/hero-bg-2.png";
import heroBackgroundThree from "../assets/hero-bg-3.png";
import { useState, useEffect } from "react";
// import ScrollIntoView from "react-scroll-into-view";

function Hero() {
  const bgArray = [heroBackgroundOne, heroBackgroundTwo, heroBackgroundThree];
  const [heroBg, setHerobg] = useState(bgArray[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setHerobg(bgArray[Math.floor(Math.random() * bgArray.length)]);
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <main
      className="hero flex justify-between items-center px-[5rem] pb-[6rem] pt-[15rem] max-lg:flex-col max-sm:px-[2rem] max-lg:pb-[3rem] max-lg:px-[3rem]"
      style={{ background: `url(${heroBg})`, transition: "all 2s ease" }}
    >
      <section>
        <h1 className="max-w-[860px] text-white text-[6.4rem] font-bold leading-none max-lg:text-[5.2rem] max-sm:text-[4.2rem] max-sm:leading-tight ">
          Your Talent
          <span className="text-[6.4rem] max-lg:text-[5.2rem] max-sm:hidden">
            ,{" "}
          </span>
          <br className="sm:hidden min-[1024px]:block xl:hidden" />
          Your Stage
          <span className="text-[6.4rem] hidden lg:inline 2xl:hidden">,</span>
          <br className="lg:hidden" /> Your Jubal
        </h1>
        <p className="text-white text-[2.4rem] mb-[2rem] mt-[3rem] w-[70%] max-sm:text-[2rem] max-sm:w-[90%] max-sm:mt-[2rem] max-sm:mb-3rem ">
          Join Africa&apos;s foremost music and creative talent marketplace.
        </p>
        <div className="sm:flex sm:items-center ">
          <div className="mb-[1rem] sm:mr-[2rem]">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSepP70miAt0Bigg3swKxjAbUk_qdSetfh9uZfOIZRkO90SDQg/viewform?pli=1">
              <button className="bg-jubalYellow text-white px-[3rem] py-[1.2rem] text-[1.8rem] rounded-[8px] max-sm:text-[1.4rem] max-sm:py-[1rem] max-sm:px-[1.5rem] shadow-[0px 4px 15px 0px #2222221A]">
                Join The Waitlist
              </button>
            </a>
          </div>
          <div>
            <a href="https://chat.whatsapp.com/LVoOl7wgP3ZC9Z5eCr9PJ5">
              <button className="bg-jubalViolet border-[2px] text-white px-[3rem] py-[1.2rem] text-[1.8rem] rounded-[8px] max-sm:text-[1.4rem] max-sm:py-[1rem] max-sm:px-[1.5rem] shadow-[0px 4px 15px 0px #2222221A]">
                Join Jubal Community
              </button>
            </a>
          </div>
        </div>
      </section>
      <section className=" w-[60%] max-sm:w-[100%] max-lg:h-[auto] max-sm:my-[2rem]">
        <img className="w-[100%] h-[auto]" src={heroImage} alt="musicians" />
      </section>
    </main>
  );
}

export default Hero;
