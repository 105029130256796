import "./App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import WhySection from "./components/WhySection";
import Form from "./components/Form";
import TestimonialSection from "./components/TestimonialSection";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <WhySection />
      <Form />
      <TestimonialSection />
      <Footer />
    </>
  );
}

export default App;
