import TestimonialBox from "./TestimonialBox";
import heroBackground from "../assets/hero-bg.jpg";
import avatarOne from "../assets/avatar1.png";
import avatarTwo from "../assets/avatar2.png";
import avatarThree from "../assets/avatar3.png";
import avatarFour from "../assets/avatar4.png";

function TestimonialSection() {
  return (
    <div
      className="mt-[3rem] px-0 py-[7rem] text-white max-sm:py-[3rem] "
      style={{ background: `url(${heroBackground})` }}
    >
      <h1 className="text-[5rem] text-center font-bold max-sm:text-[3.6rem] w-[80%] mx-auto">
        What People are saying about us
      </h1>
      <div className=" py-[10rem] flex justify-between overflow-x-auto max-sm:px-[2rem] max-sm:py-[3rem] scrollbar">
        <TestimonialBox
          image={avatarOne}
          text="Jubal has been a platform where I could share my voice and talents with the world. It connected me with clients who saw my potential, and I'm grateful for the opportunities it has brought my way."
          name="Levi"
          role="Singer/Assistant Music Director"
        />
        <TestimonialBox
          image={avatarTwo}
          text="Jubal is a game-changer for music professionals like me. It's not just a platform; it's a community that's opened doors to exciting projects and collaborations. I'm thrilled to be part of it."
          name="Kanyisola"
          role="Music Project Manager"
        />
        <TestimonialBox
          image={avatarThree}
          text="Jubal has been a blessing for me. It's where my passion for cinematography met exciting opportunities. I've worked on incredible projects thanks to this platform."
          name="Benedict"
          role="Cinematographer"
        />
        <TestimonialBox
          image={avatarFour}
          text="Jubal made finding my dream gigs a breeze. The platform connected me with clients who appreciated my work, and it's been a game-changer for my career."
          name="Chris"
          role="Videographer"
        />
      </div>
    </div>
  );
}
export default TestimonialSection;
